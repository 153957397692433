import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Hero = ({ data }) => {
  const hiro = data ? [data[0], data[1], data[2], data[3]] : [];

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div className="tile is-ancestor m-0">
      <div className="tile is-parent is-6 hf-1">
        <article
          className="tile is-child"
          style={{
            backgroundImage: `url(${hiro[0]?.image.includes("idc") ? addStr(hiro[0]?.image, 65, "576x450_") : addStr(hiro[0]?.image, 37, "576x450_")})`,
          }}
        >
          <Link to={`/${hiro[0]?.slug}`}>
            <div className="hf-details">
              <div className="category">
                <span>{hiro[0]?.categories[0]?.name}</span>
              </div>
              <div className="title">{hiro[0]?.title}</div>
            </div>
          </Link>
        </article>
      </div>
      <div className="tile is-vertical">
        <div className="tile is-parent hf-2">
          <article
            className="tile is-child"
            style={{
              backgroundImage: `url(${hiro[1]?.image.includes("idc") ? addStr(hiro[1]?.image, 65, "576x225_") : addStr(hiro[1]?.image, 37, "576x225_")})`,

              //stirng manipulation
            }}
          >
            <Link to={`/${hiro[1]?.slug}`}>
              <div className="hf-details">
                <div className="category">
                  <span>{hiro[1]?.categories[0]?.name}</span>
                </div>
                <div className="title med">{hiro[1]?.title}</div>
              </div>
            </Link>
          </article>
        </div>
        <div className="tile">
          <div className="tile is-parent is-vertical hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${hiro[2]?.image.includes("idc") ? addStr(hiro[2]?.image, 65, "288x225_") : addStr(hiro[2]?.image, 37, "288x225_")})`,
              }}
            >
              <Link to={`/${hiro[2]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{hiro[2]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{hiro[2]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
          <div className="tile is-parent hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${hiro[3]?.image.includes("idc") ? addStr(hiro[3]?.image, 65, "288x225_") : addStr(hiro[3]?.image, 37, "288x225_")})`,
              }}
            >
              <Link to={`/${hiro[3]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{hiro[3]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{hiro[3]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
