import React from "react";
// import Header from "../../../../components/header/header1/index";
import Header from "../../../../components/header/Header";
import Home from "../../../../components/category/Home";
import Footer from "../../../../components/footer/Footer";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  return (
    <div className="layout">
      <Header
        category={data?.cms?.categories}
        allPosts={allPosts}
        engine={options}
        domain={data?.cms?.domain}
        pages={data?.cms?.pages}
      />
      <Helmet>
        <meta name="commission-factory-verification" content="9a2a0128730b4b2a88a55b488e9873b8" />
        <meta name="ir-site-verification-token" value="-1113842092" />
        <meta
          name="google-site-verification"
          content="y5lV9QGSNfjz1IRHuIZcYKOT6mVeiKkTeVY9zU73eAM"
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FS55TRMLV6"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-FS55TRMLV6');`}
        </script>
      </Helmet>
      <Home data={data} domain={data?.cms?.domain} />
      <Footer
        pages={data?.cms?.pages}
        domain={data?.cms?.domain}
        email={"editor@luxuryhotels.guide"}
        add="Address: 2140 S Dupont Highway camden, Kent DE 19934"
      />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query category($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "13") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "13") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "13", category_id: $slug) {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
